<template>
  <div>
    <b-modal id="modal-request-timeoff" ref="modal" title="Add/Edit availability" cancel-variant="outline-secondary"
      @show="resetEventLocal" hide-footer :visible="isEventHandlerSidebarActive"
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)">
      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <form ref="form" @submit.prevent="handleSubmit(closeModal)">
          <b-row>
            <b-col md="6">
              <validation-provider #default="validationContext" name="Assistant" rules="required">
                <b-form-group label="Assistant" label-for="assistant" :state="getValidationState(validationContext)">

                  <v-select id="assistant" v-model="eventLocal.extendedProps.assistant" :clearable="false" label="text"
                    :options="users" :reduce="option => option.value" :state="getValidationState(validationContext)" />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group label="Start Date" label-for="name-input" invalid-feedback="Start Date is required">
                <b-form-checkbox v-model="eventLocal.extendedProps.recurrent">
                  Recurrent availability
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <validation-provider #default="validationContext" name="Date" rules="required">
                <b-form-group label="Available on:" label-for="name-input">
                  <b-form-datepicker id="start-input" v-model="eventLocal.extendedProps.date" required
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }" />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider #default="validationContext" name="Start time" rules="required">
                <b-form-group label="Start of recurrence:" label-for="name-input"
                  invalid-feedback="Start Date is required">
                  <vue-timepicker v-model="eventLocal.extendedProps.startTime" :minute-interval="15" />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider #default="validationContext" name="End time" rules="required">
                <b-form-group label="End of recurrence:" label-for="name-input" invalid-feedback="End Date is required">
                  <vue-timepicker v-model="eventLocal.extendedProps.endTime" :minute-interval="15" />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <div class="mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="w-100" type="submit">
              Save
            </b-button>
          </div>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BFormTextarea, BButton, BFormInvalidFeedback,
  BFormDatepicker, BFormTimepicker, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'
import Parse from "parse/dist/parse.min.js";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    BFormDatepicker,
    BFormTimepicker,
    BRow, BCol, VueTimepicker
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url, users: []
    }
  },
  created() {
    this.fetchUsers()
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)
    const toast = useToast()

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      resetEventLocal,
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
      toast
    }
  },
  methods: {
    fetchUsers() {
      const query = new Parse.Query(Parse.Object.extend("User"));
      const Role = Parse.Object.extend("_Role");
      const innerQuery = new Parse.Query(Role);
      innerQuery.equalTo("name", "assistant")

      query.matchesQuery("role", innerQuery);
      query
        .find()
        .then((results) => {
          this.users = results.map(user => ({
            value: user.id,
            text: `${user.attributes.firstName} ${user.attributes.lastName}`
          }));
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching user list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    closeModal() {
      this.onSubmit()
      this.$refs.modal.hide()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
